.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from   { background-color: #7ea8c1;}
  to     { background-color: #a2d1ea;}
}

/* Set the background color to light grey */
body {
  background-color: #f3f3f3;
}

/* Set the font and text color */
body, button, input {
  font-family: 'Roboto', sans-serif;
  color: #212121;
}

/* Center the main content and make it 80% width */
.container {
  margin: 0 auto;
  max-width: 80%;
}

.btn-info {
  color: #FFF;
}

/* Add some spacing between the sections */
.section {
  margin-bottom: 5rem;
}

/* Style the logo */
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.logo img {
  max-height: 7rem;
}

/* Style the "Learn More" button */
.button {
  background-color: #212121;
  color: #ffffff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button:hover {
  background-color: #ffffff;
  color: #212121;
}


.nav-bar-logo {
  padding-left: 20px;
}

.nav-logo {
  height: 80px;
  margin-right: -25px;
}

.lifetask-logo-text {
  font-weight: bold;
  text-decoration: none;
  font-size: 35px;
  color:rgba(247, 247, 247, 1)
}

.lifetask-logo-text:hover {
  color:rgba(247, 247, 247, 1)
}

.lifetask-logo-studio-text {
  color:rgb(255 78 0)
}


.navbar-animate {
  animation: fadein 4s forwards;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-general {
  background-image: url(./resources/landing_page_bg2.jpg);
  background-color: #3ca5fd;
  background-blend-mode: multiply;
}

.navbar-landing {
  background-color:transparent;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: auto;
  padding-right: 10%;
}

.nav-links li {
  margin-right: 40px;
}

.nav-links li a {
  float:left
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links li .top-level-nav-item {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.nav-links li .top-level-nav-item:hover {
  text-decoration: underline;
}

.dropdown-menu.show {
  display: flow-root;
}

p {
  text-align: left;
}

.header {
  padding: 40px 40px 30px 40px;
  text-align: center;
}

.header .tagline {
  text-align: center;
}


.button-group {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;
}

.button-group span {
  float: left;
  margin: 0 5px 0 0;
  width: 200px;
  height: 40px;
  position: relative;
}

.button-group label,
.button-group input  {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.button-group input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}

.button-group input[type="radio"]:checked+label,
.Checked+label {
  background: yellow;
}

.button-group label {
  padding: 10px;
  border: 1px solid #CCC;
  cursor: pointer;
  z-index: 90;
}

.button-group span:hover {
  background: #DDD;
}

.landing-page, .buffer{
  padding-top: 30px;
}

.about-page {
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 80px;
}

.about-page .header {
  color: white;
  font-weight: bold;
}

.about-text {
  padding: 30px;
  background:rgb(15 50 105 / 49%);
  color: white;
  border-radius:5px;
}

.about-page .description {
  font-weight: 500;
}

.bold {
  font-weight: 900 !important;
}

.landing-page a, .about-page a {
  color: white;
  font-weight:900;
}

.landing-page a:hover, .about-page a:hover {
  color: rgb(153, 136, 248);
}

.intake-form, .login-form{
  text-align: left;
}

.project_summary_box{
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  background-color: #f8f9fa; /* Optional background color */
  margin-bottom: 10px;
  list-style: none;
  font-size: 15px;
  border-radius: 5px;
}

.list-group-item {
  border: none;
  padding: 10px 20px;
  background-color: transparent; /* Optional background color */
  display: flex; /* Add display flex to each list item */
  justify-content: space-between; /* Space between spans */
  align-items: center; /* Center spans vertically */
  text-align:left;
}


/* Optional styles for the spans */
.list-group-item > span:first-child {
  font-weight: bold;
}

.list-group-item > span:nth-child(2) {
  font-weight: bold;
  color: #007bff; /* Optional color for the second span */
}

ul {
  padding: 0px;
}

/* CSS */
.unselected-image {
  filter: grayscale(100%);
}

.goal-list {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
}


.task-item{
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.task-completed{
  background-color:rgb(255, 225, 250);
}

.task-incomplete{
  background-color: #c3c3c3;
}

.task-image {
  max-width: 80px;
  margin-right: 10px;
}

.task-details {
  flex-grow: 1;
}

.task-name {
  font-size: 1.2rem;
  margin: 0;
  padding: 13px 5px;
}

.task-description {
  color: #777;
  margin: 0;
}

.material-quantity-price {
  display: flex;
  justify-content: space-between;
}

.material-quantity,
.material-price {
  font-weight: bold;
}


.bg-image-2 {
  background-color: #008bffb5;
  background-image: url(./resources/landing_page_bg2.jpg);
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  color: #fff;
  min-height: 100vh;
  padding-bottom:80px;
}

.bg-image-1 {
  background-color: #008bff47;
  background-image: url(./resources/bg4.jpg);
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  color: #fff;
  min-height: 100vh;
  padding-bottom: 80px;
}

.privacy {
  text-align: left;
}